import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { getRedirectLanguage } from '../utils/getRedirectLanguage';

const IndexPage = () => {
  useEffect(() => {
    const urlLang = getRedirectLanguage();

    navigate(`/${urlLang}/`);
  }, []);

  return null;
};

export default IndexPage;